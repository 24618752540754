<template>
  <v-container fluid>
    <v-row justify="center" style="min-height: 100vh;">
      <v-col cols="12" align-self="center">
        <v-row justify="center">
          <v-col cols="12" class="mb-4">
            <v-img src="/img/logo.png" class="mx-auto" max-width="250"></v-img>
          </v-col>
          <v-col
            lg="6"
            md="9"
            cols="11"
            class="grey lighten-4 elevation-5 pa-4 rounded-xl"
            align-self="center"
          >
            <h1>Profil erstellen</h1>
            <v-divider class="my-3"></v-divider>
            <v-stepper
              v-model="register"
              rounded="xl"
              style="background: rgba(0, 0, 0, 0);"
              flat
            >
              <v-stepper-step :complete="register > 1" step="1">
                <h2 :class="register > 1 ? 'primary--text' : ''">
                  Wer bist Du?
                  <span
                    class="font-weight-light secondary--text"
                    v-if="register > 1"
                  >
                    {{ person.vorname }} {{ person.nachname }}
                  </span>
                </h2>
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-row class="pa-2">
                  <v-col cols="auto">
                    <v-btn
                      rounded
                      :text="person.geschlecht != 'Junge'"
                      x-large
                      :color="
                        person.geschlecht == 'Junge' ? 'primary' : undefined
                      "
                      @click="person.geschlecht = 'Junge'"
                    >
                      <v-icon x-large>
                        mdi-face-man
                      </v-icon>
                      Junge
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      rounded
                      :text="person.geschlecht != 'Mädchen'"
                      x-large
                      :color="
                        person.geschlecht == 'Mädchen' ? 'primary' : undefined
                      "
                      @click="person.geschlecht = 'Mädchen'"
                    >
                      <v-icon x-large>mdi-face-woman</v-icon>
                      Mädchen
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      light
                      rounded
                      filled
                      autocomplete="off"
                      label="Vorname"
                      v-model="person.vorname"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      light
                      rounded
                      filled
                      autocomplete="off"
                      label="Nachname"
                      v-model="person.nachname"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="rounded-xl"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="convertDate(person.geburtsdatum)"
                          filled
                          rounded
                          label="Geburtsdatum"
                          autocomplete="off"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="person.geburtsdatum"
                        locale="de"
                        class="rounded-xl"
                        content-class="rounded-xl"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000,
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      rounded
                      x-large
                      color="primary"
                      :disabled="
                        !person.geschlecht ||
                        !person.vorname ||
                        !person.nachname ||
                        !person.geburtsdatum
                      "
                      @click="register = 2"
                    >
                      Weiter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-step step="2" :complete="register > 2">
                <h2 :class="register > 2 ? 'primary--text' : ''">
                  Welcher Sport interessiert dich?
                </h2>
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-row class="pa-2">
                  <v-col cols="12" class="text-left">
                    <h4>
                      <span v-if="minsportarten > 0">
                        Bitte wähle noch mindestens
                        {{ minsportarten }} Sportarten aus:
                      </span>
                      &nbsp;
                    </h4>
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    v-for="(sport, index) in sportarten.data"
                    :key="index"
                  >
                    <v-card
                      link
                      outlined
                      :color="
                        person.sportarten.includes(sport.name)
                          ? 'primary'
                          : undefined
                      "
                      class="rounded-xl h-100 pa-2"
                      :style="
                        person.sportarten.includes(sport.name)
                          ? 'color: #FFFFFF;'
                          : undefined
                      "
                      @click="select(sport.name)"
                    >
                      <v-row justify="center" class="h-100">
                        <v-col
                          cols="12"
                          class="text-center"
                          align-self="center"
                        >
                          <v-icon
                            x-large
                            :color="
                              person.sportarten.includes(sport.name)
                                ? 'white'
                                : undefined
                            "
                          >
                            {{ sport.icon }}
                          </v-icon>
                          <h3>
                            {{ sport.name }}
                          </h3>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="text-left">
                    <v-btn
                      text
                      rounded
                      x-large
                      color="primary"
                      @click="register = 1"
                    >
                      Zurück
                    </v-btn>
                    <v-btn
                      rounded
                      color="primary"
                      @click="register = 3"
                      :disabled="person.sportarten.length < 3"
                      x-large
                    >
                      Weiter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-step step="3" :complete="register > 3">
                <h2 :class="register > 3 ? 'primary--text' : ''">
                  Daten deiner Erziehungsberechtigten
                </h2>
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-row class="pa-2">
                  <v-col cols="10" md="5">
                    <v-text-field
                      light
                      filled
                      rounded
                      label="Vorname"
                      hide-details
                      v-model="person.erziehungsberechtigte[0].vorname"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" md="5">
                    <v-text-field
                      light
                      filled
                      rounded
                      hide-details
                      label="Nachname"
                      v-model="person.erziehungsberechtigte[0].nachname"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" md="5">
                    <v-text-field
                      light
                      filled
                      rounded
                      hide-details
                      label="E-Mail"
                      v-model="person.erziehungsberechtigte[0].email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" class="text-left">
                    <v-btn
                      x-large
                      text
                      rounded
                      color="primary"
                      @click="register = 2"
                    >
                      Zurück
                    </v-btn>
                    <v-btn
                      x-large
                      rounded
                      color="primary"
                      :disabled="
                        !person.erziehungsberechtigte[0].vorname ||
                        !person.erziehungsberechtigte[0].nachname ||
                        !person.erziehungsberechtigte[0].email
                      "
                      @click="saveUser()"
                    >
                      Weiter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import router from '../../router'
import { doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore'

export default {
  name: 'Profil-vervollständigen',
  data() {
    return {
      //sports: [],
      register: 1,
      person: {
        vorname: '',
        nachname: '',
        geburtsdatum: '',
        sportarten: [],
        geschlecht: '',
        erziehungsberechtigte: [
          {
            vorname: '',
            nachname: '',
            email: '',
          },
        ],
      },
      minvalue: 3,
      activePicker: null,
      date: null,
      menu: false,
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted() {
    /*firebase
      .firestore()
      .collection('Sportarten')
      .get()
      .then((snap) => {
        snap.forEach((art) => {
          this.sports.push(art.data())
        })
      })*/
  },
  computed: {
    ...mapGetters({
      user: 'user',
      db: 'db',
      sportarten: 'sportarten',
    }),
    /*sportarten() {
      var arr = this.sports
      arr.sort((a, b) => {
        if (a.title > b.title) {
          return 1
        } else {
          return -1
        }
      })

      return arr
    },*/
    minsportarten() {
      return this.minvalue - this.person.sportarten.length
    },
  },
  methods: {
    select(title) {
      if (this.person.sportarten.includes(title)) {
        var old = this.person.sportarten
        this.person.sportarten = []
        old.forEach((s) => {
          if (s != title) {
            this.person.sportarten.push(s)
          }
        })
      } else {
        this.person.sportarten.push(title)
      }
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    convertDate(date) {
      if (!date) {
        return date
      }
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    saveUser() {
      var userdoc = doc(this.db, 'User', this.user.uid)
      setDoc(userdoc, this.person).then(() => {
        router.push('/')
      })
    },
  },
}
</script>
